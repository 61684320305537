export const APPLICATION_NAME = "SafeDrive";

const currentDomain = window.location.hostname;

export const BACKEND_URL = (function () {
  // return "https://api.safedrive.pl/"
  // return "http://sdapi-dev.eu-central-1.elasticbeanstalk.com/"

  // return "http://localhost:3000/"
  switch (currentDomain) {
    case "system.safedrive.pl":
      return "https://api.safedrive.pl/";
    case "sd-app-dev.s3-website.eu-central-1.amazonaws.com":
      return "http://sdapi-dev.eu-central-1.elasticbeanstalk.com/";
    case "system.sp.pl":
      return "https://api.sp.pl/";
    case "localhost":
      // return "http://api.sp.pl/";
      return "http://localhost:3000/";
    // return "http://192.168.1.102:3000"
    default:
      return `http://${currentDomain}:3000/`;
  }
})();
